<template>
<div>
    <h3>Templates</h3>
    <hr>
    <b-container>
        <b-row>
            <b-col>
                <b-button @click="addTemplate" variant="outline-success">
                    <b-icon icon="plus-circle"></b-icon> Add
                </b-button>
            </b-col>
            <b-col>
                <b-form-select
                    v-model="compSelect"
                    :options="compOptions"
                    :disabled="compSelDisable"
                    @change="compSelChange"
                >
                </b-form-select>
            </b-col>
        </b-row>
    </b-container>
    <hr>
    <b-table class="text-center"
        striped
        bordered
        small
        hover
        selectable
        ref="selectableTable"
        select-mode="single"
        sticky-header="true"
        head-variant="light"
        responsive="sm"
        :items="items"
        :fields="fields"
        @row-selected="onRowSelected"
    >
        <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
            </template>
            <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
            </template>
        </template>
        <template #cell(edit)="row">
            <b-icon-pencil-fill @click="editItem(row.index)"></b-icon-pencil-fill>
        </template>
        <template #cell(delete)="row">
            <b-icon-trash-fill @click="delItem(row.index)"></b-icon-trash-fill>
        </template>
    </b-table>
    <b-modal ref="confirm-modal" hide-footer centered title="Confirmation">
        <div class="d-block text-center">
            <h5>Delete Template: {{selTempName}} ?</h5>
        </div>
        <hr>
        <b-container class="text-center">
            <b-row cols="2">
                <b-col>
                    <b-button variant="danger" block @click="deleteYes">Yes (No Undo)</b-button>
                </b-col>
                <b-col>
                    <b-button variant="primary" block @click="deleteNo">No</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
    <b-modal
        ref="add-temp-modal"
        hide-footer
        centered
        title="Add Template"
    >
        <div>
            <b-container >
                <b-form @submit="tempOK">
                    <b-form-group>
                        <label class="m-25" for="sys-addtemp">Name:</label>
                        <b-form-input
                            class="m-75"
                            id="sys-addtemp"
                            type="text"
                            v-model="form.name"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <hr>
                    <b-container class="text-center">
                        <b-row cols="2">
                            <b-col>
                                <b-button variant="success" type="submit" block >OK</b-button>
                            </b-col>
                            <b-col>
                                <b-button variant="warning" block @click="tempCancel">Cancel</b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form>
            </b-container>
        </div>
    </b-modal>
    <b-modal
        ref="edit-temp-modal"
        hide-footer
        centered
        title="Edit Name"
    >
        <div>
            <b-container >
                <b-form @submit="tempEditOK">
                    <b-form-group>
                        <label class="m-25" for="sys-edit-temp-old">Old Name:</label>
                        <b-form-input
                            readonly
                            class="m-75"
                            id="sys-edit-temp-old"
                            type="text"
                            :placeholder="selTempName"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <label class="m-25" for="sys-edit-temp">New Name:</label>
                        <b-form-input
                            class="m-75"
                            id="sys-edit-temp"
                            type="text"
                            v-model="form.name"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <hr>
                    <b-container class="text-center">
                        <b-row cols="2">
                            <b-col>
                                <b-button variant="success" type="submit" block >OK</b-button>
                            </b-col>
                            <b-col>
                                <b-button variant="warning" block @click="tempEditCancel">Cancel</b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form>
            </b-container>
        </div>
    </b-modal>
</div>
</template>

<script>
import {EventBus} from "@/plugins/event-bus";
import roles from '@/roles';
export default {
    name: "TemplateList",
    data() {
        return {
            fields: [
                {key: 'selected', label: 'Selected', thStyle: { width: '5rem'}},
                {key: 'name', label: 'Name'},
                {key: 'edit', label: 'Edit'},
                {key: 'delete', label: 'Delete'}
            ],
            selected: [],
            form: {
                name: ''
            },
            itemSelIdx: 0,
            compSelect: 0,
            compSelDisable: false
        }
    },
    created() {
        this.$store.dispatch('getTemplateSchedules');

    },
    mounted() {
        this.compSelect = this.$store.getters.company;
        if (this.$store.getters.role !== roles.roles.superUser)
            this.compSelDisable = true;
    },
    methods: {
        compSelChange(value) {
            this.$store.commit('storeTemplateCompSel', value);
        },
        onRowSelected(items) {
            this.selected = items
            this.$store.commit('storeSelectedTemplates', this.selected[0]);
            EventBus.$emit("refreshSched");
        },
        addTemplate() {
            //console.log("Add Template");
            this.form.name = '';
            this.$refs['add-temp-modal'].show();
        },
        editItem(itemIndex) {
            this.itemSelIdx = itemIndex;
            this.form.name = '';
            this.$refs['edit-temp-modal'].show();
        },
        // eslint-disable-next-line no-unused-vars
        delItem(itemIndex) {
            const tempSchRef = this.$store.getters.templateSchedules;
            for (let i = 0; i < tempSchRef.length; i++) {
                if (tempSchRef[i].company === this.compSelect) {
                    if (this.selected !== null || this.selected !== undefined) {
                        if (this.selected.length > 0) {
                            if (tempSchRef[i].name === this.selected[0].name) {
                                this.itemSelIdx = i;
                                this.$refs['confirm-modal'].show();
                            }
                        }
                    }
                }
            }
        },
        tempOK(event) {
            event.preventDefault();
            let newSch = {
                name: this.form.name,
                company: this.compSelect,
                sch: []
            };
            this.$store.commit('addTemplateSchedule', newSch);
            this.selected = newSch;
            this.$store.commit('storeSelectedTemplates', this.selected);
            EventBus.$emit("refreshSched");
            this.$refs['add-temp-modal'].hide();
            setTimeout(() => {
                this.$refs.selectableTable.selectRow(this.$store.getters.templateSchedules.length - 1);
            }, 500);
        },
        tempCancel() {
            this.$refs['add-temp-modal'].hide();
        },
        tempEditOK(event) {
            event.preventDefault();
            const oldName = this.$store.getters.templateSchedules[this.itemSelIdx].name;
            this.$refs['edit-temp-modal'].hide();
            this.$store.dispatch('editTemplateName', {
                oldname: oldName,
                newname: this.form.name
            }).then(() => {
                this.$store.dispatch('getTemplateSchedules').then(() => {
                    this.$bvToast.toast('Template Edited', {
                        title: 'Template Edit',
                        variant: 'success',
                        solid: true
                    });
                    EventBus.$emit("refreshSched");
                }).catch(() => {

                });
            }).catch(() => {
                this.$bvToast.toast('Error Editing Template', {
                    title: 'Template Edit',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        tempEditCancel() {
            this.$refs['edit-temp-modal'].hide();
        },
        deleteYes() {
            this.$refs['confirm-modal'].hide();
            const delName = this.$store.getters.templateSchedules[this.itemSelIdx].name;
            this.$store.dispatch('deleteTemplate', delName).then(() => {
                this.$store.dispatch('getTemplateSchedules').then(() => {
                    this.$bvToast.toast('Template Deleted', {
                        title: 'Template Delete',
                        variant: 'success',
                        solid: true
                    });
                    EventBus.$emit("refreshSched");
                }).catch(() => {

                });
            }).catch(() => {
                this.$bvToast.toast('Error Deleting Template', {
                    title: 'Template Delete',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        deleteNo() {
            this.$refs['confirm-modal'].hide();
        }
    },
    computed: {
        items() {
            const tempSch = this.$store.getters.templateSchedules;
            let templateSched = [];
            for (let i = 0; i < tempSch.length; i++) {
                if (tempSch[i].company === this.compSelect)
                    templateSched.push(tempSch[i]);
            }
            return templateSched;
        },
        selTempName() {
            if (this.$store.getters.templateSchedules[this.itemSelIdx] !== undefined)
                return this.$store.getters.templateSchedules[this.itemSelIdx].name;
            else
                return '';
        },
        compOptions() {
            const cList = this.$store.getters.compList;
            let cNameList = [];
            if (cList !== null) {
                for (let i = 0; i < cList.length; i++) {
                    let comp = {
                        text: cList[i].name,
                        value: i
                    };
                    cNameList.push(comp);
                }
            }
            return cNameList;
        }
    }
}
</script>

<style scoped>

</style>
